import type { FC } from 'react';
import React from 'react';

import Head from 'next/head';
import Image from 'next/image';

import { useCurrentUserRedirect } from 'hooks/useCurrentUserRedirect';

import landingImage from '../../../../public/img/landing.png';

export const AuthLayout: FC = ({ children }) => {
  useCurrentUserRedirect();

  return (
    <div className="flex min-h-screen bg-white">
      <Head>
        <title>PARSIQ</title>
      </Head>
      {children}
      <div className="relative hidden w-0 flex-1 lg:block">
        <Image
          src={landingImage}
          alt="Parsiq abstract image"
          className="absolute inset-0 h-full w-full object-cover object-right"
          priority
          quality={100}
          layout="fill"
        />
      </div>
    </div>
  );
};
