import React from 'react';

import Image from 'next/image';

import Logo from '../../../../public/img/logo.svg';

export const FormContainer: React.FC<{ headerText: string }> = ({
  headerText,
  children,
}) => (
  <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <Image className="h-12 w-auto" src={Logo} alt="Parsiq" />
        <h2 className="mt-6 text-3xl font-extrabold text-pgray-700">
          {headerText}
        </h2>
      </div>
      <div className="mt-8">{children}</div>
    </div>
  </div>
);
