import {
  FacebookIcon,
  GithubIcon,
  GoogleIcon,
  TwitterIcon,
} from 'components/icon';
import { isSocialLoginNetworkEnabled } from 'utils/features';

export const socials = [
  {
    name: 'Google',
    icon: GoogleIcon,
    enabled: isSocialLoginNetworkEnabled('google'),
  },
  {
    name: 'Twitter',
    icon: TwitterIcon,
    enabled: isSocialLoginNetworkEnabled('twitter'),
  },
  {
    name: 'Facebook',
    icon: FacebookIcon,
    enabled: isSocialLoginNetworkEnabled('facebook'),
  },
  {
    name: 'GitHub',
    icon: GithubIcon,
    enabled: isSocialLoginNetworkEnabled('github'),
  },
];
