import type { ReactElement } from 'react';

import { AuthLayout } from 'features/auth/layout/AuthLayout';
import { Login } from 'features/auth/login/Login';

const LoginPage = () => {
  return <Login />;
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout>{page}</AuthLayout>;
};

export default LoginPage;
