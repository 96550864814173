import { APP_CONFIG } from 'consts/config';
import { Features, isFeatureEnabled } from 'utils/features';

import { SocialLoginCard } from './SocialLoginCard';
import { socials } from './socials';

type Props = {
  ctaText?: string;
};

const enabledSocials = socials.filter(({ enabled }) => enabled);

const gridColClass: Record<1 | 2 | 3 | 4, string> = {
  '1': 'grid-cols-1',
  '2': 'grid-cols-2',
  '3': 'grid-cols-3',
  '4': 'grid-cols-4',
};

export const SocialLoginButtons = ({ ctaText = 'Sign in with' }: Props) => {
  if (!isFeatureEnabled(Features.SocialLogins)) {
    return null;
  }

  return (
    <>
      <div>
        <p className="text-sm font-medium text-pgray-700">{ctaText}</p>

        <div
          className={`mt-1 grid ${gridColClass[enabledSocials.length]} gap-3`}
        >
          {enabledSocials.map(({ name, icon }) => (
            <SocialLoginCard
              key={name}
              href={`${
                APP_CONFIG.backendUrl
              }/social-auth/${name.toLowerCase()}/session`}
              title={`Sign in with ${name}`}
              icon={icon}
            />
          ))}
        </div>
      </div>

      <div className="relative mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border border-t border-pgray-300" />
        </div>
        <div className="relative mb-6 flex justify-center text-sm">
          <span className="bg-white px-2 text-pgray-500">Or continue with</span>
        </div>
      </div>
    </>
  );
};
