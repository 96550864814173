import { APP_CONFIG } from 'consts/config';

export enum Features {
  SocialLogins,
}

const featureMap: Record<Features, boolean> = {
  [Features.SocialLogins]: !!APP_CONFIG.socialLogins,
};

export const isFeatureEnabled = (feature: Features) => featureMap[feature];

export const isSocialLoginNetworkEnabled = (social: string) => {
  if (!APP_CONFIG.socialLogins) {
    return false;
  }

  return APP_CONFIG.socialLogins.split(',').includes(social);
};
