import React from 'react';

type SocialLoginProps = {
  href: string;
  title: string;
  icon: React.ElementType;
};

export const SocialLoginCard = ({
  href = '',
  title = 'Sign in with Facebook',
  icon: Icon,
}: SocialLoginProps) => (
  <div>
    <a
      href={href}
      className="inline-flex w-full justify-center rounded-md border border-solid border-pgray-300 bg-white py-2 px-4 text-sm font-medium text-pgray-500 shadow-sm hover:bg-pgray-50"
    >
      <span className="sr-only">{title}</span>
      <Icon className="h-5 w-5" />
    </a>
  </div>
);
