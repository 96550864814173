import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { NonNullProps } from 'tsdef';
import { object, string } from 'yup';

import { useLogin } from 'api';
import { Button } from 'components/button';
import { Input, MultiFieldContainer, GeneralFormError } from 'components/form';
import { StyledRouterLink } from 'components/styled-link';
import { useSetFormErrors } from 'hooks/useSetFormErrors';
import { useCurrentUser } from 'store';

import { FormContainer } from '../layout/FormContainer';
import { ROUTE_AUTH_REGISTER, ROUTE_FORGOT_PASSWORD } from '../routes';
import { SocialLoginButtons } from '../social/SocialLoginButtons';

export type FormValues = {
  email: string;
  password: string;
};

export const validationSchema = object().shape({
  email: string().email('Invalid email').required('This field is required'),
  password: string()
    .required('This field is required')
    .min(10, 'Password must be longer than or equal to 10 characters'),
});

const LoginForm = () => {
  const { setCurrentUser } = useCurrentUser();
  const { mutateAsync: login } = useLogin();

  const {
    handleSubmit,
    control,
    register,
    formState: { isSubmitting },
    setError,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const setFormErrors = useSetFormErrors(setError);

  const onSubmit: SubmitHandler<NonNullProps<Required<FormValues>>> = async (
    values
  ) => {
    try {
      const loginResponse = await login(values);
      setCurrentUser(loginResponse);

      if (window.opener !== null) {
        window.close();
      }
    } catch (e) {
      setFormErrors(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <MultiFieldContainer>
        <GeneralFormError register={register} control={control} />
        <Input
          name="email"
          control={control}
          label="Email"
          placeholder="Email"
        />
        <Input
          name="password"
          control={control}
          label="Password"
          placeholder="Password"
          type="password"
        />
      </MultiFieldContainer>
      <div className="my-6">
        <Button
          type="submit"
          fullWidth
          disabled={isSubmitting}
          label="Sign in"
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="text-sm">
          <StyledRouterLink href={ROUTE_FORGOT_PASSWORD}>
            Forgot your password?
          </StyledRouterLink>
        </div>
        <div className="text-sm">
          <StyledRouterLink href={ROUTE_AUTH_REGISTER}>
            Sign up
          </StyledRouterLink>
        </div>
      </div>
    </form>
  );
};

export const Login = () => (
  <FormContainer headerText="Sign in to your account">
    <SocialLoginButtons />
    <LoginForm />
  </FormContainer>
);
